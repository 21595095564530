// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/atlas-medium-aaa.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/atlas-regular-aaa.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/noyland-bold-aaa.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "atlas-aaa";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff");
}

@font-face {
    font-family: "atlas-aaa";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
}

@font-face {
    font-family: "noyland-aaa";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff");
}

* {
    font-family: "atlas-aaa";
}
`, "",{"version":3,"sources":["webpack://./css/fonts/font.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,2DAAwD;AAC5D;;AAEA;IACI,wBAAwB;IACxB,2DAAyD;AAC7D;;AAEA;IACI,0BAA0B;IAC1B,2DAAwD;AAC5D;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":["@font-face {\r\n    font-family: \"atlas-aaa\";\r\n    src: url(\"./fonts/atlas-medium-aaa.woff\") format(\"woff\");\r\n}\r\n\r\n@font-face {\r\n    font-family: \"atlas-aaa\";\r\n    src: url(\"./fonts/atlas-regular-aaa.woff\") format(\"woff\");\r\n}\r\n\r\n@font-face {\r\n    font-family: \"noyland-aaa\";\r\n    src: url(\"./fonts/noyland-bold-aaa.woff\") format(\"woff\");\r\n}\r\n\r\n* {\r\n    font-family: \"atlas-aaa\";\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
