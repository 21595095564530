// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-block {
    background-color: #fff;
    width: 100%;
    /* border-left: 3px solid #dddddd; */
    display: none;
    overflow: visible;
    min-width: 400px;
    max-width: 850px;
}

.sidebar-block > div {
    display: none;
}

.sidebar-block .icon-x {
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./css/sidebar-block.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,WAAW;IACX,oCAAoC;IACpC,aAAa;IACb,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".sidebar-block {\r\n    background-color: #fff;\r\n    width: 100%;\r\n    /* border-left: 3px solid #dddddd; */\r\n    display: none;\r\n    overflow: visible;\r\n    min-width: 400px;\r\n    max-width: 850px;\r\n}\r\n\r\n.sidebar-block > div {\r\n    display: none;\r\n}\r\n\r\n.sidebar-block .icon-x {\r\n    cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
