// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/new/icomoon2.eot?bkv5n7", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/new/icomoon2.ttf?bkv5n7", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/icomoon.woff?bkv5n7", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/new/icomoon2.svg?bkv5n7", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#icomoon2" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {  font-family: 'icomoon2';

  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("embedded-opentype"),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype"), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff"),
    url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon2-"], [class*=" icon2-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon2' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon2-close:before {
  content: "\\e900";
}
.icon2-copy:before {
  content: "\\e901";
  color: #7a7c8c;
}
.icon2-delete:before {
  content: "\\e902";
  color: #7a7c8c;
}
.icon2-publish:before {
  content: "\\e903";
  color: #7a7c8c;
}
`, "",{"version":3,"sources":["webpack://./css/icons-font/style2.css"],"names":[],"mappings":"AAAA,cAAc,uBAAuB;;EAEnC,4CAA2C;EAC3C;;yDAE+D;EAC/D,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,+EAA+E;EAC/E,kCAAkC;EAClC,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;EACpB,oBAAoB;EACpB,cAAc;;EAEd,sCAAsC;EACtC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":["@font-face {  font-family: 'icomoon2';\r\n\r\n  src: url(\"./fonts/new/icomoon2.eot?bkv5n7\");\r\n  src: url(\"./fonts/new/icomoon2.eot?bkv5n7#iefix\") format(\"embedded-opentype\"),\r\n    url(\"./fonts/new/icomoon2.ttf?bkv5n7\") format(\"truetype\"), url(\"./fonts/icomoon.woff?bkv5n7\") format(\"woff\"),\r\n    url(\"./fonts/new/icomoon2.svg?bkv5n7#icomoon2\") format(\"svg\");\r\n  font-weight: normal;\r\n  font-style: normal;\r\n}\r\n\r\n[class^=\"icon2-\"], [class*=\" icon2-\"] {\r\n  /* use !important to prevent issues with browser extensions that change fonts */\r\n  font-family: 'icomoon2' !important;\r\n  speak: never;\r\n  font-style: normal;\r\n  font-weight: normal;\r\n  font-variant: normal;\r\n  text-transform: none;\r\n  line-height: 1;\r\n\r\n  /* Better Font Rendering =========== */\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\n.icon2-close:before {\r\n  content: \"\\e900\";\r\n}\r\n.icon2-copy:before {\r\n  content: \"\\e901\";\r\n  color: #7a7c8c;\r\n}\r\n.icon2-delete:before {\r\n  content: \"\\e902\";\r\n  color: #7a7c8c;\r\n}\r\n.icon2-publish:before {\r\n  content: \"\\e903\";\r\n  color: #7a7c8c;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
