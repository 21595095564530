// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./icon-v4/icon4.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./icon-v4/icon4.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./icon-v4/icon4.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./icon-v4/icon4.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#icon4" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "icon4";
  src:url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  src:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("embedded-opentype"),
      url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff"),
      url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype"),
      url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "icon4" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon4-"]:before,
[class*=" icon4-"]:before {
  font-family: "icon4" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon4-multiple-bookmark:before {
  content: "\\61";
}
.icon4-multiple-bookmark-o:before {
  content: "\\62";
}
`, "",{"version":3,"sources":["webpack://./css/icons-font/icon-v4.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,2CAA8B;EAC9B;;;2DAGkD;EAClD,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;EAC/B,wBAAwB;EACxB,6BAA6B;EAC7B,8BAA8B;EAC9B,+BAA+B;EAC/B,+BAA+B;EAC/B,WAAW;EACX,cAAc;EACd,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;;EAEE,+BAA+B;EAC/B,6BAA6B;EAC7B,8BAA8B;EAC9B,+BAA+B;EAC/B,+BAA+B;EAC/B,WAAW;EACX,cAAc;EACd,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB","sourcesContent":["@font-face {\r\n  font-family: \"icon4\";\r\n  src:url(\"./icon-v4/icon4.eot\");\r\n  src:url(\"./icon-v4/icon4.eot?#iefix\") format(\"embedded-opentype\"),\r\n      url(\"./icon-v4/icon4.woff\") format(\"woff\"),\r\n      url(\"./icon-v4/icon4.ttf\") format(\"truetype\"),\r\n      url(\"./icon-v4/icon4.svg#icon4\") format(\"svg\");\r\n  font-weight: normal;\r\n  font-style: normal;\r\n}\r\n\r\n[data-icon]:before {\r\n  font-family: \"icon4\" !important;\r\n  content: attr(data-icon);\r\n  font-style: normal !important;\r\n  font-weight: normal !important;\r\n  font-variant: normal !important;\r\n  text-transform: none !important;\r\n  speak: none;\r\n  line-height: 1;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\n[class^=\"icon4-\"]:before,\r\n[class*=\" icon4-\"]:before {\r\n  font-family: \"icon4\" !important;\r\n  font-style: normal !important;\r\n  font-weight: normal !important;\r\n  font-variant: normal !important;\r\n  text-transform: none !important;\r\n  speak: none;\r\n  line-height: 1;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\n.icon4-multiple-bookmark:before {\r\n  content: \"\\61\";\r\n}\r\n.icon4-multiple-bookmark-o:before {\r\n  content: \"\\62\";\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
