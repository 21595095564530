// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-label {
  position: relative;
  display: block;
  height: 16px;
  width: 32px;
  border: solid 2px #dddddd;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.toggle-label:after {
  position: absolute;
  left: 0px;
  top: -1px;
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 100px;
  background: #dddddd;
  box-shadow: 0px 3px 3px rgba(#000, 0.05);
  content: "";
  transition: all 0.3s ease;
}
.toggle-label:active:after {
  transform: scale(1.15, 0.85);
}

.toggle-input:checked ~ label {
  border: solid 2px #5075e7;
}
.toggle-input:checked ~ label:after {
  left: 14px;
  background: #5075e7;
}
/* .toggle-input:disabled ~ label {
  background: #d5d5d5;
  pointer-events: none;
}
.toggle-input:disabled ~ label:after {
  background: #bcbdbc;
} */
.hidden {
  display: none;
}
.toggle-text {
  color: #dddddd;
  font-family: "atlas-aaa";
  font-size: 16px;
  line-height: 16px;
  margin-right: 7px;
  transition: all 0.3s ease;
}
.toggle-text.active {
  color: #5075e7;
}
`, "",{"version":3,"sources":["webpack://./css/materials-toggle.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,oBAAoB;EACpB,eAAe;EACf,yBAAyB;AAC3B;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,SAAS;EACT,cAAc;EACd,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,mBAAmB;EACnB,wCAAwC;EACxC,WAAW;EACX,yBAAyB;AAC3B;AACA;EACE,4BAA4B;AAC9B;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,UAAU;EACV,mBAAmB;AACrB;AACA;;;;;;GAMG;AACH;EACE,aAAa;AACf;AACA;EACE,cAAc;EACd,wBAAwB;EACxB,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;EACE,cAAc;AAChB","sourcesContent":[".toggle-label {\r\n  position: relative;\r\n  display: block;\r\n  height: 16px;\r\n  width: 32px;\r\n  border: solid 2px #dddddd;\r\n  border-radius: 100px;\r\n  cursor: pointer;\r\n  transition: all 0.3s ease;\r\n}\r\n.toggle-label:after {\r\n  position: absolute;\r\n  left: 0px;\r\n  top: -1px;\r\n  display: block;\r\n  width: 14px;\r\n  height: 14px;\r\n  border-radius: 100px;\r\n  background: #dddddd;\r\n  box-shadow: 0px 3px 3px rgba(#000, 0.05);\r\n  content: \"\";\r\n  transition: all 0.3s ease;\r\n}\r\n.toggle-label:active:after {\r\n  transform: scale(1.15, 0.85);\r\n}\r\n\r\n.toggle-input:checked ~ label {\r\n  border: solid 2px #5075e7;\r\n}\r\n.toggle-input:checked ~ label:after {\r\n  left: 14px;\r\n  background: #5075e7;\r\n}\r\n/* .toggle-input:disabled ~ label {\r\n  background: #d5d5d5;\r\n  pointer-events: none;\r\n}\r\n.toggle-input:disabled ~ label:after {\r\n  background: #bcbdbc;\r\n} */\r\n.hidden {\r\n  display: none;\r\n}\r\n.toggle-text {\r\n  color: #dddddd;\r\n  font-family: \"atlas-aaa\";\r\n  font-size: 16px;\r\n  line-height: 16px;\r\n  margin-right: 7px;\r\n  transition: all 0.3s ease;\r\n}\r\n.toggle-text.active {\r\n  color: #5075e7;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
