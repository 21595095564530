// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#notify {
  position: fixed;
  right: 20px;
  top: 20px;
  padding: 15px;
  min-width: 320px;
  max-width: 400px;
  border-radius: 4px;
  z-index: 9999;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  transition: all 0.4s;
  /*background-color: rgba(11, 15, 55, 0.9);*/
}
#notify .icon-x:before {
  color: #fff;
}
#notify .icon-x {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}
#notify.show {
  opacity: 1;
  visibility: visible;
  -ms-transform: translateY(0);
  transform: translateY(0);
}
#notify.success {
  background-color: #7cc62b;
}
#notify.error {
  background: #fe7878
}
`, "",{"version":3,"sources":["webpack://./css/notify.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,SAAS;EACT,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,kBAAkB;EAClB,UAAU;EACV,+BAA+B;EAC/B,2BAA2B;EAC3B,oBAAoB;EACpB,2CAA2C;AAC7C;AACA;EACE,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,eAAe;AACjB;AACA;EACE,UAAU;EACV,mBAAmB;EACnB,4BAA4B;EAC5B,wBAAwB;AAC1B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE;AACF","sourcesContent":["#notify {\r\n  position: fixed;\r\n  right: 20px;\r\n  top: 20px;\r\n  padding: 15px;\r\n  min-width: 320px;\r\n  max-width: 400px;\r\n  border-radius: 4px;\r\n  z-index: 9999;\r\n  color: #fff;\r\n  visibility: hidden;\r\n  opacity: 0;\r\n  -ms-transform: translateY(30px);\r\n  transform: translateY(30px);\r\n  transition: all 0.4s;\r\n  /*background-color: rgba(11, 15, 55, 0.9);*/\r\n}\r\n#notify .icon-x:before {\r\n  color: #fff;\r\n}\r\n#notify .icon-x {\r\n  position: absolute;\r\n  top: 10px;\r\n  left: 10px;\r\n  cursor: pointer;\r\n}\r\n#notify.show {\r\n  opacity: 1;\r\n  visibility: visible;\r\n  -ms-transform: translateY(0);\r\n  transform: translateY(0);\r\n}\r\n#notify.success {\r\n  background-color: #7cc62b;\r\n}\r\n#notify.error {\r\n  background: #fe7878\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
