// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.db-word-wrapper {
  position: absolute;
  z-index: 1;
  display: none;
}
.border-word {
  border: 1px solid rgb(24, 181, 16);
  position: absolute;
  display: none;
}

.db-word {
  text-align: center;
  color: #2e59da;
  border: none;
  background: none;
  width: 100%;
  position: absolute;
  user-select: none;
}
.db-word:active ~ .word-tooltip-wrapper,
.db-word:focus ~ .word-tooltip-wrapper,
.db-word.active ~ .word-tooltip-wrapper {
  display: block;
}
.editing-word .db-word-wrapper,
.editing-word .border-word,
.db-word-wrapper.active,
.border-word.active {
  display: block;
}
.editor-toggle {
  pointer-events: none;
  opacity: 0.5;
  display: none;
}
.editor-toggle.show {
  display: block;
}
.editor-toggle.clickable {
  pointer-events: auto;
  opacity: 1;
}
.rectangle {
  border: 1px solid rgb(24, 181, 16);
  position: absolute;
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./css/words-editor.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,aAAa;AACf;AACA;EACE,kCAAkC;EAClC,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,iBAAiB;AACnB;AACA;;;EAGE,cAAc;AAChB;AACA;;;;EAIE,cAAc;AAChB;AACA;EACE,oBAAoB;EACpB,YAAY;EACZ,aAAa;AACf;AACA;EACE,cAAc;AAChB;AACA;EACE,oBAAoB;EACpB,UAAU;AACZ;AACA;EACE,kCAAkC;EAClC,kBAAkB;EAClB,UAAU;AACZ","sourcesContent":[".db-word-wrapper {\r\n  position: absolute;\r\n  z-index: 1;\r\n  display: none;\r\n}\r\n.border-word {\r\n  border: 1px solid rgb(24, 181, 16);\r\n  position: absolute;\r\n  display: none;\r\n}\r\n\r\n.db-word {\r\n  text-align: center;\r\n  color: #2e59da;\r\n  border: none;\r\n  background: none;\r\n  width: 100%;\r\n  position: absolute;\r\n  user-select: none;\r\n}\r\n.db-word:active ~ .word-tooltip-wrapper,\r\n.db-word:focus ~ .word-tooltip-wrapper,\r\n.db-word.active ~ .word-tooltip-wrapper {\r\n  display: block;\r\n}\r\n.editing-word .db-word-wrapper,\r\n.editing-word .border-word,\r\n.db-word-wrapper.active,\r\n.border-word.active {\r\n  display: block;\r\n}\r\n.editor-toggle {\r\n  pointer-events: none;\r\n  opacity: 0.5;\r\n  display: none;\r\n}\r\n.editor-toggle.show {\r\n  display: block;\r\n}\r\n.editor-toggle.clickable {\r\n  pointer-events: auto;\r\n  opacity: 1;\r\n}\r\n.rectangle {\r\n  border: 1px solid rgb(24, 181, 16);\r\n  position: absolute;\r\n  z-index: 1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
