// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tooltip {
  user-select: none;
  display: none;
  position: absolute;
  bottom: calc(100% + 30px);
  border-radius: 3px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.27);
  background-color: rgba(11, 15, 55, 0.9);
  padding: 10px 15px;
  font-family: "atlas-aaa";
  font-size: 14px;
  line-height: 1.14;
  text-align: center;
  color: #ffffff;
  transition: 150ms;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  min-width: 126px;
  max-width: 126px;
  z-index: 110;
  justify-content: center;
}
.custom-tooltip:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: rgba(11, 15, 55, 0.9) transparent transparent transparent;
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
}

.custom-tooltip-bottom {
  bottom: -250%;
  left: 0;
  min-width: max-content;
}

.custom-tooltip-bottom:after {
  border-width: 10px;
  border-color: transparent transparent rgba(11, 15, 55, 0.9) transparent;
  top: -17px;
}
`, "",{"version":3,"sources":["webpack://./css/tooltip.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,0CAA0C;EAC1C,uCAAuC;EACvC,kBAAkB;EAClB,wBAAwB;EACxB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,SAAS;EACT,2BAA2B;EAC3B,UAAU;EACV,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;EACZ,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,QAAQ;EACR,SAAS;EACT,mBAAmB;EACnB,8BAA8B;EAC9B,uEAAuE;EACvE,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,OAAO;EACP,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,uEAAuE;EACvE,UAAU;AACZ","sourcesContent":[".custom-tooltip {\r\n  user-select: none;\r\n  display: none;\r\n  position: absolute;\r\n  bottom: calc(100% + 30px);\r\n  border-radius: 3px;\r\n  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.27);\r\n  background-color: rgba(11, 15, 55, 0.9);\r\n  padding: 10px 15px;\r\n  font-family: \"atlas-aaa\";\r\n  font-size: 14px;\r\n  line-height: 1.14;\r\n  text-align: center;\r\n  color: #ffffff;\r\n  transition: 150ms;\r\n  left: 50%;\r\n  transform: translateX(-50%);\r\n  opacity: 0;\r\n  min-width: 126px;\r\n  max-width: 126px;\r\n  z-index: 110;\r\n  justify-content: center;\r\n}\r\n.custom-tooltip:after {\r\n  content: \"\";\r\n  width: 0;\r\n  height: 0;\r\n  border-style: solid;\r\n  border-width: 10px 10px 0 10px;\r\n  border-color: rgba(11, 15, 55, 0.9) transparent transparent transparent;\r\n  position: absolute;\r\n  bottom: -9px;\r\n  left: 50%;\r\n  transform: translateX(-50%);\r\n}\r\n\r\n.custom-tooltip-bottom {\r\n  bottom: -250%;\r\n  left: 0;\r\n  min-width: max-content;\r\n}\r\n\r\n.custom-tooltip-bottom:after {\r\n  border-width: 10px;\r\n  border-color: transparent transparent rgba(11, 15, 55, 0.9) transparent;\r\n  top: -17px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
