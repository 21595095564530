// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../imgs/drag.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gutter {
    position: relative;
    background-color: #d8d8d8 !important;
    transition: 150ms;
    display: none;
}
.gutter:hover {
    background-color: #6c90ff !important;
}
.gutter:hover:after {
    background-color: #6c90ff !important;
}

.gutter:after {
    content: "";
    display: block;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #d8d8d8;
    width: 30px !important;
    height: 70px !important;
    position: absolute;
    border-radius: 6px 0 0 6px;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    transition: 150ms;
}
`, "",{"version":3,"sources":["webpack://./css/split.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,oCAAoC;IACpC,iBAAiB;IACjB,aAAa;AACjB;AACA;IACI,oCAAoC;AACxC;AACA;IACI,oCAAoC;AACxC;;AAEA;IACI,WAAW;IACX,cAAc;IACd,oEAAoD;IACpD,4BAA4B;IAC5B,2BAA2B;IAC3B,yBAAyB;IACzB,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,0BAA0B;IAC1B,UAAU;IACV,QAAQ;IACR,2BAA2B;IAC3B,iBAAiB;AACrB","sourcesContent":[".gutter {\r\n    position: relative;\r\n    background-color: #d8d8d8 !important;\r\n    transition: 150ms;\r\n    display: none;\r\n}\r\n.gutter:hover {\r\n    background-color: #6c90ff !important;\r\n}\r\n.gutter:hover:after {\r\n    background-color: #6c90ff !important;\r\n}\r\n\r\n.gutter:after {\r\n    content: \"\";\r\n    display: block;\r\n    background-image: url(\"../imgs/drag.svg\") !important;\r\n    background-repeat: no-repeat;\r\n    background-position: center;\r\n    background-color: #d8d8d8;\r\n    width: 30px !important;\r\n    height: 70px !important;\r\n    position: absolute;\r\n    border-radius: 6px 0 0 6px;\r\n    right: 0px;\r\n    top: 50%;\r\n    transform: translateY(-50%);\r\n    transition: 150ms;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
